<template>
  <div class="appManage">
    <a-form :form="form" layout="inline" style="margin-bottom: 20px">
      <a-form-item label="AppId">
        <a-input placeholder="请输入应用Id" v-decorator="['id']"></a-input>
      </a-form-item>
      <a-form-item label="应用名称">
        <a-input
          placeholder="请输入应用名称"
          v-decorator="['clientName']"
        ></a-input>
      </a-form-item>
      <!-- <a-form-item label="英文名称">
        <a-input
          placeholder="请输入关键词"
          v-decorator="['appNameEn']"
        ></a-input>
      </a-form-item> -->
      <a-form-item>
        <a-button type="primary" style="margin-right: 8px" @click="search"
          >查询</a-button
        >
        <a-button @click="reset">重置</a-button>
      </a-form-item>
    </a-form>

    <a-button type="primary" @click="addApp">添加</a-button>
    <div class="des_box">
      <a-tag color="blue">
        <div>
          <a-icon type="info-circle" theme="filled" style="color: #2d8cf0" />
          客户端模式，请授权相关接口资源。否则请求网关服务器将提示<span
            >“权限不足，拒绝访问！”</span
          >
        </div>
      </a-tag>
    </div>
    <a-table
      :columns="columns"
      :data-source="appData"
      bordered
      :pagination="pagination"
      @change="tableChange"
      :scroll="{ x: 1500 }"
      :rowKey="(record) => record.appId"
      style="margin-top: 15px"
    >
      <div slot="appType" slot-scope="text">
        <a-tag color="blue">
          {{ appType[text] }}
        </a-tag>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="editApp(record)" style="margin-right: 10px">编辑</a>
        <a-dropdown>
          <a @click="(e) => e.preventDefault()">更多<a-icon type="down" /></a>
          <a-menu slot="overlay" :style="{ fontSize: '12px' }">
            <a-menu-item @click="reSet(record)">重置密钥</a-menu-item>
            <a-menu-item @click="delApp(record)">删除应用</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </a-table>
    <!-- 应用设置/编辑/添加 -->
    <app-set
      :visible.sync="appVisible"
      :appInfo="appInfo"
      v-if="appVisible"
      @refresh="refresh"
    ></app-set>
  </div>
</template>

<script>
import AppSet from './modal/appSet.vue'

const columns = [
  {
    dataIndex: 'clientName',
    key: 'clientName',
    title: '应用名称',
    width: '200px',
    align: 'left',
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: 'AppId',
    width: '300px',
    align: 'left',
  },
  {
    dataIndex: 'clientId',
    key: 'clientId',
    title: 'ApiKey',
    width: '350px',
    align: 'left',
  },
  // {
  //   dataIndex: 'secretKey',
  //   key: 'secretKey',
  //   title: 'SecretKey',
  //   width: '300px',
  //   align: 'left',
  // },
  {
    dataIndex: 'appAuthor',
    key: 'appAuthor',
    title: '开发者',
    align: 'left',

    // width: '100px',
  },
  {
    dataIndex: 'appType',
    key: 'appType',
    title: '应用类型',
    scopedSlots: { customRender: 'appType' },
    width: '150px',
    align: 'left',

    filters: [
      {
        text: '服务器应用',
        value: 'server',
      },
      {
        text: '手机应用',
        value: 'app',
      },
      {
        text: '手机网页应用',
        value: 'wap',
      },
      {
        text: 'PC网页应用',
        value: 'pc',
      },
    ],
    onFilter: (value, record) => record.appType.indexOf(value) === 0,
  },

  {
    dataIndex: 'operation',
    key: 'operation',
    title: '操作',
    scopedSlots: { customRender: 'operation' },
    fixed: 'right',
    width: '130px',
    align: 'left',
  },
]
export default {
  name: 'AppManage',
  components: { AppSet },
  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      appData: [],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },
      appType: {
        server: '服务器应用',
        app: '手机应用',
        wap: '手机网页应用',
        pc: 'PC网页应用',
      },
      appVisible: false,
      appInfo: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getAppData({ size: 10, page: 1 })
    },
    /* 获取app列表 */
    getAppData(params) {
      let searchValue = this.form.getFieldsValue()
      params = {
        ...params,
        ...searchValue,
      }
      return new Promise((resolve, reject) => {
        this.$api
          .getClientList(params)
          .then((res) => {
            console.log(res, '应用列表')
            if (res.code == '0') {
              // this.appData = res.data.records
              // this.pagination.total = Number(res.data.total)
              this.appData = res.data.content
              this.pagination.total = Number(res.data.totalElements)
            } else {
              this.$message('应用列表获取失败')
            }
          })
          .catch((err) => {})
      })
    },
    search() {
      this.getAppData({ size: this.pagination.pageSize, page: 1 })
    },
    reset() {
      this.form.resetFields()
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getAppData({ size: 10, page: 1 })
    },
    /* 表格变化（分页） */
    tableChange(pagination) {
      let { current, pageSize } = pagination
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getAppData({ page: current, size: pageSize })
    },
    editApp(record) {
      //   console.log(record)
      this.appInfo = record
      this.appVisible = true
    },
    refresh() {
      this.appVisible = false
      this.init()
    },
    /* 添加客户端app */
    addApp() {
      this.appInfo = {}
      this.appVisible = true
    },
    /* 重置密钥 */
    reSet(record) {
      let _this = this
      this.$confirm({
        title: '重置后将影响应用正常使用，确定重置吗？',
        onOk() {
          // _this.$api.reset(record.appId).then((res) => {
          //   console.log(res, '重置秘钥')
          //   if (res.code == 0) {
          //     _this.$message.success('重置成功')
          //     _this.refresh()
          //   } else {
          //     _this.$message.error(res.message)
          //   }
          // })
        },
        onCancel() {},
      })
    },
    /* 删除应用 */
    delApp(record) {
      let _this = this
      this.$confirm({
        title: '删除后将无法恢复，确定删除吗？',
        onOk() {
          _this.$api.deleteClient(record.id).then((res) => {
            console.log(res, '删除')
            _this.$message.success('删除成功')
            _this.refresh()
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.appManage {
  background-color: #fff;
  padding: 15px;
}

/deep/ .ant-table-body {
  background-color: #fff;
  font-size: 12px;
}

/deep/ .ant-table-row-cell-break-word {
  font-size: 12px;
}

/deep/ .ant-table-column-title {
  font-weight: bold;
}

/deep/ .ant-table-align-center,
.ant-table-align-left {
  height: 42px !important;
  padding: 0;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

/deep/ .des_box {
  margin-top: 10px;
  .ant-tag {
    width: 100%;
    height: 30px;
    div {
      height: 100%;
      line-height: 30px;
      color: #515a6e;
      span {
        color: #c7254e;
        background-color: #f6f6f6;
      }
    }
  }
}
</style>
