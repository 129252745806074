var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"width":"800px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.tabChange}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"应用信息"}},[_c('a-form',{attrs:{"form":_vm.infoForm,"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-form-item',{attrs:{"label":"应用图标"}},[_c('a-upload',{attrs:{"name":"avatar","customRequest":_vm.fileUpload,"show-upload-list":false,"list-type":"picture-card"},on:{"change":_vm.handleChange}},[(_vm.imageUrl)?_c('img',{attrs:{"src":_vm.imageUrl,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1)])],1),_c('a-form-item',{attrs:{"label":"AppId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id', { initialValue: _vm.appInfo.id }]),expression:"['id', { initialValue: appInfo.id }]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"开发者"}},[_c('a-select',{attrs:{"placeholder":"请选择"}})],1),_c('a-form-item',{attrs:{"label":"应用名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'clientName',
              {
                rules: [{ required: true, message: '请输入应用名称' }],
                initialValue: _vm.appInfo.clientName,
              } ]),expression:"[\n              'clientName',\n              {\n                rules: [{ required: true, message: '请输入应用名称' }],\n                initialValue: appInfo.clientName,\n              },\n            ]"}],attrs:{"placeholder":"请输入内容"}})],1),_c('a-form-item',{attrs:{"label":"应用类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'appType',
              {
                rules: [{ required: false, message: '请选择应用类型' }],
                initialValue: _vm.appInfo.appType,
              } ]),expression:"[\n              'appType',\n              {\n                rules: [{ required: false, message: '请选择应用类型' }],\n                initialValue: appInfo.appType,\n              },\n            ]"}],attrs:{"placeholder":"请选择"}},[_c('a-select-option',{attrs:{"value":"server"}},[_vm._v("服务器应用")]),_c('a-select-option',{attrs:{"value":"app"}},[_vm._v("手机应用")]),_c('a-select-option',{attrs:{"value":"wap"}},[_vm._v("手机网页应用")]),_c('a-select-option',{attrs:{"value":"pc"}},[_vm._v("PC网页应用")])],1)],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: _vm.appInfo.status }]),expression:"['status', { initialValue: appInfo.status }]"}]},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v("上线")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v("下线")])],1)],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{attrs:{"placeholder":"请输入内容","rows":3}})],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"开发信息"}},[_c('a-form',{attrs:{"form":_vm.developForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"ApiKey"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['clientId', { initialValue: _vm.appInfo.clientId }]),expression:"['clientId', { initialValue: appInfo.clientId }]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"授权类型"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'authorizationGrantTypes',
              {
                rules: [{ required: true, message: '请选择' }],
                initialValue: _vm.appInfo.authorizationGrantTypes,
              } ]),expression:"[\n              'authorizationGrantTypes',\n              {\n                rules: [{ required: true, message: '请选择' }],\n                initialValue: appInfo.authorizationGrantTypes,\n              },\n            ]"}],attrs:{"options":_vm.options}})],1),_c('a-form-item',{attrs:{"label":"客户端认证方法"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'clientAuthenticationMethods',
              {
                rules: [{ required: true, message: '请选择' }],
                initialValue: _vm.appInfo.clientAuthenticationMethods,
              } ]),expression:"[\n              'clientAuthenticationMethods',\n              {\n                rules: [{ required: true, message: '请选择' }],\n                initialValue: appInfo.clientAuthenticationMethods,\n              },\n            ]"}],attrs:{"options":_vm.options2}})],1),_c('a-form-item',{attrs:{"label":"用户授权范围"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'authorized_scope',
              { rules: [{ required: false, message: '请选择' }] } ]),expression:"[\n              'authorized_scope',\n              { rules: [{ required: false, message: '请选择' }] },\n            ]"}],attrs:{"disabled":""}},[_c('a-checkbox',{attrs:{"value":"1"}},[_vm._v("允许访问基本信息")]),_c('a-checkbox',{attrs:{"value":"2"}},[_vm._v("允许访问...自定义信息")])],1)],1),_c('a-form-item',{attrs:{"label":"自动授权范围"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['auto_authorized_scope']),expression:"['auto_authorized_scope']"}],attrs:{"disabled":""}},[_c('a-checkbox',{attrs:{"value":"1"}},[_vm._v("允许访问基本信息")]),_c('a-checkbox',{attrs:{"value":"2"}},[_vm._v("允许访问...自定义信息")])],1)],1),_c('a-form-item',{attrs:{"label":"访问令牌有效期"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'access_token_time_to_live',
              {
                rules: [{ required: true, message: '请输入' }],
                initialValue: _vm.isEdit
                  ? _vm.appInfo.tokenSettings[
                      'settings.token.access-token-time-to-live'
                    ] || ''
                  : '',
              } ]),expression:"[\n              'access_token_time_to_live',\n              {\n                rules: [{ required: true, message: '请输入' }],\n                initialValue: isEdit\n                  ? appInfo.tokenSettings[\n                      'settings.token.access-token-time-to-live'\n                    ] || ''\n                  : '',\n              },\n            ]"}]}),_vm._v(" 秒 ")],1),_c('a-form-item',{attrs:{"label":"刷新令牌有效期"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'refresh_token_time_to_live',
              {
                rules: [{ required: true, message: '请输入' }],
                initialValue: _vm.isEdit
                  ? _vm.appInfo.tokenSettings[
                      'settings.token.refresh-token-time-to-live'
                    ] || ''
                  : '',
              } ]),expression:"[\n              'refresh_token_time_to_live',\n              {\n                rules: [{ required: true, message: '请输入' }],\n                initialValue: isEdit\n                  ? appInfo.tokenSettings[\n                      'settings.token.refresh-token-time-to-live'\n                    ] || ''\n                  : '',\n              },\n            ]"}]}),_vm._v(" 秒 ")],1),_c('a-form-item',{attrs:{"label":"第三方登录回调地址"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'redirectUris',
              {
                rules: [{ required: true, message: '请输入回调地址' }],
                initialValue: _vm.isEdit
                  ? _vm.appInfo.redirectUris.join(",\n") || ''
                  : '',
              } ]),expression:"[\n              'redirectUris',\n              {\n                rules: [{ required: true, message: '请输入回调地址' }],\n                initialValue: isEdit\n                  ? appInfo.redirectUris.join(`,\\n`) || ''\n                  : '',\n              },\n            ]"}],attrs:{"rows":5,"placeholder":"请输入内容"}})],1),_c('a-form-item',{attrs:{"label":"scopes"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'scopes',
              {
                rules: [{ required: false, message: '请输入回调地址' }],
                initialValue: _vm.isEdit ? _vm.appInfo.scopes.join(",\n") || '' : '',
              } ]),expression:"[\n              'scopes',\n              {\n                rules: [{ required: false, message: '请输入回调地址' }],\n                initialValue: isEdit ? appInfo.scopes.join(`,\\n`) || '' : '',\n              },\n            ]"}],attrs:{"rows":5,"placeholder":"请输入内容"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }