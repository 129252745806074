<template>
  <a-modal
    :visible="visible"
    :title="title"
    @ok="handleOk"
    @cancel="handleCancel"
    width="800px"
  >
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="应用信息">
        <!-- 应用信息表单 -->
        <a-form
          :form="infoForm"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <!-- <a-form-item
            label="应用图标"
            :label-col="{ span:  6 }"
            :wrapper-col="{ span: 20 }"
          >
            <a-upload></a-upload>
          </a-form-item> -->
          <a-form-item label="应用图标">
            <a-upload
              name="avatar"
              :customRequest="fileUpload"
              :show-upload-list="false"
              list-type="picture-card"
              @change="handleChange"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="AppId">
            <a-input
              disabled
              v-decorator="['id', { initialValue: appInfo.id }]"
            ></a-input>
          </a-form-item>
          <a-form-item label="开发者">
            <a-select placeholder="请选择"></a-select>
          </a-form-item>
          <a-form-item label="应用名称">
            <a-input
              placeholder="请输入内容"
              v-decorator="[
                'clientName',
                {
                  rules: [{ required: true, message: '请输入应用名称' }],
                  initialValue: appInfo.clientName,
                },
              ]"
            ></a-input>
          </a-form-item>
          <!-- <a-form-item label="英文名称">
            <a-input
              placeholder="请输入内容"
              v-decorator="[
                'appNameEn',
                {
                  rules: [{ required: true, message: '请输入应用英文名称' }],
                  initialValue: appInfo.appNameEn,
                },
              ]"
            ></a-input>
          </a-form-item> -->
          <a-form-item label="应用类型">
            <a-select
              placeholder="请选择"
              v-decorator="[
                'appType',
                {
                  rules: [{ required: false, message: '请选择应用类型' }],
                  initialValue: appInfo.appType,
                },
              ]"
            >
              <a-select-option value="server">服务器应用</a-select-option>
              <a-select-option value="app">手机应用</a-select-option>
              <a-select-option value="wap">手机网页应用</a-select-option>
              <a-select-option value="pc">PC网页应用</a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="应用官网">
            <a-input placeholder="请输入内容"></a-input>
          </a-form-item> -->
          <a-form-item label="状态">
            <a-radio-group
              v-decorator="['status', { initialValue: appInfo.status }]"
            >
              <a-radio-button :value="1">上线</a-radio-button>
              <a-radio-button :value="0">下线</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea placeholder="请输入内容" :rows="3"></a-textarea>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="开发信息">
        <!-- 开发信息表单 -->
        <a-form
          :form="developForm"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="ApiKey">
            <a-input
              disabled
              v-decorator="['clientId', { initialValue: appInfo.clientId }]"
            ></a-input>
          </a-form-item>
          <!-- <a-form-item label="SecretKey">
            <a-input
              disabled
              v-decorator="['secretKey', { initialValue: appInfo.secretKey }]"
            ></a-input>
          </a-form-item> -->
          <a-form-item label="授权类型">
            <a-checkbox-group
              :options="options"
              v-decorator="[
                'authorizationGrantTypes',
                {
                  rules: [{ required: true, message: '请选择' }],
                  initialValue: appInfo.authorizationGrantTypes,
                },
              ]"
            >
            </a-checkbox-group>
          </a-form-item>
          <a-form-item label="客户端认证方法">
            <a-checkbox-group
              :options="options2"
              v-decorator="[
                'clientAuthenticationMethods',
                {
                  rules: [{ required: true, message: '请选择' }],
                  initialValue: appInfo.clientAuthenticationMethods,
                },
              ]"
            >
            </a-checkbox-group>
          </a-form-item>

          <a-form-item label="用户授权范围">
            <a-checkbox-group
              disabled
              v-decorator="[
                'authorized_scope',
                { rules: [{ required: false, message: '请选择' }] },
              ]"
            >
              <a-checkbox value="1">允许访问基本信息</a-checkbox>
              <a-checkbox value="2">允许访问...自定义信息</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <a-form-item label="自动授权范围">
            <a-checkbox-group v-decorator="['auto_authorized_scope']" disabled>
              <a-checkbox value="1">允许访问基本信息</a-checkbox>
              <a-checkbox value="2">允许访问...自定义信息</a-checkbox>
            </a-checkbox-group>
          </a-form-item>
          <!-- <a-form-item label="令牌有效期">
            <a-radio-group
              v-decorator="[
                'token',
                { rules: [{ required: true, message: '请选择' }] },
              ]"
              @change="onChange"
            >
              <a-radio-button :value="true">设置有效期</a-radio-button>
              <a-radio-button :value="false">不限制</a-radio-button>
            </a-radio-group>
          </a-form-item> -->
          <!-- <a-form-item label="访问令牌有效期" v-if="isShowTokenPeriod"> -->
          <a-form-item label="访问令牌有效期">
            <a-input-number
              v-decorator="[
                'access_token_time_to_live',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: isEdit
                    ? appInfo.tokenSettings[
                        'settings.token.access-token-time-to-live'
                      ] || ''
                    : '',
                },
              ]"
            ></a-input-number>
            秒
          </a-form-item>
          <!-- <a-form-item label="刷新令牌有效期" v-if="isShowTokenPeriod"> -->
          <a-form-item label="刷新令牌有效期">
            <a-input-number
              v-decorator="[
                'refresh_token_time_to_live',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: isEdit
                    ? appInfo.tokenSettings[
                        'settings.token.refresh-token-time-to-live'
                      ] || ''
                    : '',
                },
              ]"
            ></a-input-number>
            秒
          </a-form-item>
          <a-form-item label="第三方登录回调地址">
            <a-textarea
              :rows="5"
              placeholder="请输入内容"
              v-decorator="[
                'redirectUris',
                {
                  rules: [{ required: true, message: '请输入回调地址' }],
                  initialValue: isEdit
                    ? appInfo.redirectUris.join(`,\n`) || ''
                    : '',
                },
              ]"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="scopes">
            <a-textarea
              :rows="5"
              placeholder="请输入内容"
              v-decorator="[
                'scopes',
                {
                  rules: [{ required: false, message: '请输入回调地址' }],
                  initialValue: isEdit ? appInfo.scopes.join(`,\n`) || '' : '',
                },
              ]"
            ></a-textarea>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <!-- <a-tab-pane key="3" tab="分配权限">
        <a-form
          :form="permissionForm"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-item label="过期时间">
            <a-date-picker v-decorator="['date']" />
          </a-form-item>
          <a-form-item label="功能接口">
            <a-transfer
              :titles="['选择接口', '已选择接口']"
              :data-source="allocationData"
              show-search
              :filter-option="filterOption"
              :target-keys="targetKeys"
              :render="
                (item) =>
                  `${item.prefix.split('/**')[0]}${item.path} - ${
                    item.methodName
                  }`
              "
              :rowKey="(record) => record.apiId"
              @change="handleChange"
              @search="handleSearch"
              :list-style="{
                width: '300px',
                height: '800px',
              }"
            />
          </a-form-item>
        </a-form>
      </a-tab-pane> -->
    </a-tabs>
  </a-modal>
</template>

<script>
import { fullFileUpload } from '@/utils/newFileUpload.js'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'AppSet',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    appInfo: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    console.log(this.appInfo)
    this.init()
    // this.getTenantList()
  },
  data() {
    return {
      isEdit: Object.keys(this.appInfo).length > 0,
      title: '',
      disabled: false,
      activeTabKey: '1',
      infoForm: this.$form.createForm(this),
      developForm: this.$form.createForm(this),
      permissionForm: this.$form.createForm(this),
      options: [
        { label: '授权码模式', value: 'authorization_code' },
        { label: '客户端模式', value: 'client_credentials' },
        // { label: '密码模式', value: 'password' },
        { label: '刷新令牌', value: 'refresh_token' },
      ],
      options2: [
        { label: 'client_secret_basic', value: 'client_secret_basic' },
        { label: 'client_secret_post', value: 'client_secret_post' },
        { label: 'none', value: 'none' },
      ],
      // previewVisible: false,
      imageUrl: '',
      loading: false,
      fileList: [],
      isShowTokenPeriod: false,
      /* -------- */
      allocationData: [],
      targetKeys: [],
      alreadyTenantIdArr: [],
      alreadyTenantArr: [],
    }
  },
  methods: {
    /* 是否设置令牌有效期切换 */
    onChange(e) {
      console.log(e.target.value)
      if (e.target.value) {
        this.isShowTokenPeriod = true
      } else {
        this.isShowTokenPeriod = false
      }
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleOk() {
      let formValue1 = this.infoForm.getFieldsValue()
      let formValue2 = this.developForm.getFieldsValue()
      console.log(formValue1, formValue2)

      this.infoForm.validateFields((err, value) => {
        if (err) {
          return
        } else {
          formValue1 = value
        }
      })

      this.developForm.validateFields((err, value) => {
        if (err) {
          return
        } else {
          formValue2 = value
        }
      })

      let data = {
        clientName: formValue1.clientName,
        clientSettings: {
          'settings.client.require-proof-key': false,
          'settings.client.require-authorization-consent': false,
        },
        authorizationGrantTypes: formValue2.authorizationGrantTypes,
        clientAuthenticationMethods: formValue2.clientAuthenticationMethods,
        tokenSettings: {
          'settings.token.reuse-refresh-tokens': true,
          'settings.token.authorization-code-time-to-live': 300,
          'settings.token.access-token-time-to-live':
            formValue2.access_token_time_to_live,
          'settings.token.refresh-token-time-to-live':
            formValue2.refresh_token_time_to_live,
        },
        // redirectUris: ['http://localhost:8080/callzone/callback'],
        redirectUris: formValue2.redirectUris
          .split(',')
          .map((item) => item.replace('\n', '')) || [formValue2.redirectUris],
        // scopes: ['openid'],
        // scopes: this.appInfo.scopes,
        scopes: formValue2.scopes
          .split(',')
          .map((item) => item.replace('\n', '')) || [formValue2.scopes],
      }

      console.log(data)

      if (this.isEdit) {
        /* 更新客户端设置 */

        this.updateClient(this.appInfo.id, data).then(() => {
          this.$emit('refresh')
        })
      } else {
        /* 注册新客户端 */

        this.addClient(data).then(() => {
          this.$emit('refresh')
        })
      }

      //   this.$emit('refresh'),
      // switch (this.activeTabKey) {
      //   case '1':
      //     this.infoForm.validateFields((err, values) => {
      //       if (!err) {
      //         console.log(values)
      //       }
      //     })
      //     break
      //   case '2':
      //     this.developForm.validateFields((err, values) => {
      //       if (!err) {
      //         console.log(values)
      //       }
      //     })
      //     break
      // }
    },
    /* 修改客户端设置、内容信息 */
    updateClient(id, data) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateClient(id, data)
          .then((res) => {
            console.log(res, '更新客户端')
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    /* 注册客户端 */
    addClient(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .addClient(data)
          .then((res) => {
            console.log(res, '注册客户端')
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    init() {
      this.title =
        Object.keys(this.appInfo).length > 0
          ? `编辑应用 - ${this.appInfo.clientName}`
          : `添加应用`
      this.disabled = Object.keys(this.appInfo).length > 0 ? false : true

      this.activeTabKey = '2'
      this.$nextTick(() => {
        this.activeTabKey = '1'
      })
    },
    tabChange(activeKey) {
      console.log(activeKey)
      this.activeTabKey = activeKey
    },

    handleChange({ fileList }) {
      this.fileList = fileList
    },
    /* 文件上传 */
    fileUpload(file) {
      this.loading = true
      fullFileUpload(file.file, 1)
        .then((res) => {
          console.log(res, '文件上传成功')
          this.logoSrc = res.fileUrl
          this.imageUrl = res.fileUrl
          this.loading = false
          file.onSuccess()
        })
        .catch((err) => {
          file.onError()
        })
    },

    /* 穿梭时触发 */
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    /* 穿梭框搜索是触发 */
    handleSearch(direction, value) {},
    /* 搜索筛选方法 */
    filterOption(inputValue, option) {
      if (
        option.rentname.indexOf(inputValue) > -1 ||
        option.id.indexOf(inputValue) > -1
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-form {
  font-size: 12px !important;
}
</style>
